import React from 'react';
import {Link, useLocation} from "react-router-dom";
import sportsHubLogo from "../img/sportsHubIcon.png";
import {useTranslation} from "react-i18next";

const HeaderV2 = () =>
{
    const location = useLocation();
    const {t, i18n} = useTranslation();

    return (<div className={"header-v2 " + (location.pathname === "/" ? "bg-gradient" : "bg-white")}>
        <ul>
            <li className={"logo-li"}>
                <Link to="/">
                    <div className="c--anim-btn">
                        <span className={"c-anim-btn"}>
                            <img src={sportsHubLogo} alt={"SportsHubIcon"} className="sportsLogo"/>
                        </span>
                        <span>
                            Home
                        </span>
                    </div>
                    {/*<h2>Home</h2>*/}
                </Link>
            </li>
            <li>
                <Link to="/">
                    <h2>{t("menu.m1")}</h2>
                </Link>
            </li>
            <li>
                <Link to="/">
                    <h2>{t("menu.m2")}</h2>
                </Link>
            </li>
            <li>
                <Link to="/">
                    <h2>{t("menu.m3")}</h2>
                </Link>
            </li>
            <li>
                <Link to="">
                    <h2>{t("menu.m4")}</h2>
                </Link>
            </li>
            <li style={{display: "inline", whiteSpace: "nowrap"}}>
                <span className={"sw-lng"}>RO </span>
                <label className="switch" htmlFor={"lng"}>
                    <input type="checkbox" id={"lng"} name={"lng"}
                           onClick={() => i18n.changeLanguage(i18n.resolvedLanguage === "en" ? "ro" : "en")}
                           defaultChecked={i18n.resolvedLanguage === "en"}/>
                    <span className="slider round"></span>
                </label>
                <span className={"sw-lng"}> EN</span>
            </li>
        </ul>

    </div>);
};

export default HeaderV2;
