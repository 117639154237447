import {FullPage, Slide} from "react-full-page";
import virgil1 from "../img/virgilPhoto1.jpg";
import VirgilLogo from "./VirgilLogo";
import basketballPlayer3 from "../img/virgilFig.png";
import laur from "../img/laur5-black.png";
import steaua from "../img/steaua.svg";
import sportsHub from "../img/sportsHub.png";
import sported from "../img/sported.jpg";
import speaker from "../img/speaker.png";
import dots from "../img/Dots.svg";
import quotation from "../img/QuotationVirgil.svg";
import "../css/stanescu.css";
import "../css/sport.css";
import {useTranslation} from "react-i18next";

function VirgilStanescu()
{
    const {t} = useTranslation();

    return (
        <div className="home stanescu">
            <div className="parent-slide">
                <div className={"page-slide"}>
                    <section className="main-content height100 position-relative p-0">
                        <div className="color-block position-absolute d-none d-lg-block"></div>
                        <div className="row home-details-container align-items-center">
                            <div className="d-lg-block letter d-none">S</div>
                            <img src={laur} className="laur" alt="laur"/>
                            <div className="home-details">
                                <div>
                                    <h1 className="text-uppercase vTitle">
                                        Virgil Stănescu
                                    </h1>
                                    <img
                                        src={basketballPlayer3}
                                        className="figure"
                                        alt="basketballPlayer"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className={"page-slide"}>

                    <VirgilLogo/>

                    <div className="about pt-13vh">
                        <section
                            className="main-content pt-10 ">
                            <div className="container">
                                <div className="row position-relative">
                                    <div className="col-12">
                                        <h1 className="text-uppercase pb-5 mb-0 text-right custom-title ft-wt-600">
                                            {t("stanescu.slide1.title")}
                                        </h1>
                                    </div>
                                    <div className="col-lg-1"></div>

                                    <div className="col-lg-6 p-font text-justified">
                                        <p>
                                            {t("stanescu.slide1.text1")}
                                        </p>
                                    </div>
                                    <div className="col-lg-1"></div>

                                    <div className="col-lg-4">
                                        <img
                                            src={virgil1}
                                            alt="Virgil Stanescu"
                                            className="bmwLogo"
                                        />
                                        <div className="position-relative">
                                            <img
                                                src={dots}
                                                alt="dots"
                                                className="dots"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <div className={"page-slide"}>
                    <VirgilLogo/>
                    <div className="blog-post pt-13vh about">
                        <section className="">
                            <div className="container">
                                <div className="row">
                                    <article className="col-12 ">
                                        <h1>{t("stanescu.slide2.title")}</h1>
                                        {/*<div className="position-relative size-0">
                                            <img
                                                src={quotation}
                                                alt="quotation"
                                                className="quotation"
                                            />
                                        </div>*/}

                                        {/* <img
                                            src={virgil2}
                                            className="secondSlideImg"
                                            alt="Virgil Stanescu"
                                        /> */}

                                        <div className="blog-except pb-5 p-font text-justified">
                                            <p>
                                                {t("stanescu.slide2.text1")}
                                            </p>
                                            <p>
                                                {t("stanescu.slide2.text2")}
                                            </p>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="box-stats with-margin">
                                                    <h3 className="position-relative">
                                                        4k
                                                    </h3>
                                                    <p className="open-sans-font m-0 position-relative text-uppercase">
                                                        Likes
                                                        <span className="d-block">
                                                            on Facebook
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="box-stats with-margin">
                                                    <h3 className="position-relative">
                                                        2k
                                                    </h3>
                                                    <p className="open-sans-font m-0 position-relative text-uppercase">
                                                        Instagram
                                                        <span className="d-block">
                                                            followers
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <div className={"page-slide"}>
                    <VirgilLogo/>

                    <div className="about pt-13vh">
                        <section
                            className="main-content pt-10 ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <h1 className="text-uppercase pb-5 mb-0 text-right custom-title ft-wt-600">
                                            {t("stanescu.slide3.title")}
                                        </h1>
                                    </div>
                                    <div className="col-lg-2 col-sm-0"></div>
                                    <div className="col-lg-10 col-sm-12 p-font text-justified">
                                        <p>
                                            {t("stanescu.slide3.text1")}
                                        </p>

                                        <div className="row mt50">
                                            <div className="col-sm-6">
                                                <iframe className="videoYt" title="video"
                                                        src="https://www.youtube.com/embed/tgbNymZ7vqY"></iframe>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="position-relative">
                                                    <img src={steaua} alt="Steaua"
                                                         className="bmwLogo position-absolute"/>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

                <div className={"page-slide"}>
                    <VirgilLogo/>

                    <div className="about pt-13vh">
                        <section
                            className="main-content pt-10 ">
                            <div className="container">
                                <div className="row position-relative">
                                    <div className="col-lg-2 col-sm-0"></div>
                                    <div className="col-lg-10 col-sm-12">
                                        <h1 className="text-uppercase pb-5 mb-0 text-left custom-title ft-wt-600">
                                            {t("stanescu.slide4.title")}
                                        </h1>
                                    </div>
                                    <div className="col-lg-2 col-sm-0"></div>

                                    <div className="col-lg-6 col-sm-8 p-font text-justified">
                                        <p>
                                            {t("stanescu.slide4.text1")}
                                        </p>
                                    </div>
                                    <div className="col-lg-4">
                                        <img
                                            src={sportsHub}
                                            alt="SportsHub"
                                            className="bmwLogo"
                                        />
                                    </div>
                                    <div className="letter2-container">
                                        <div className="d-lg-block letter2">S</div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <div className={"page-slide"}>
                    <VirgilLogo/>

                    <div className="about pt-13vh">
                        <section
                            className="main-content pt-10 ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <h1 className="text-uppercase pb-5 mb-0 text-right custom-title ft-wt-600 ">
                                            {t("stanescu.slide5.title")}
                                        </h1>
                                    </div>
                                    <div className="col-lg-2 col-sm-0"></div>
                                    <div className="col-lg-10 col-sm-12 text-right p-font ">
                                        <p className="text-justified">
                                            {t("stanescu.slide5.text1")}
                                        </p>
                                        <img
                                            src={sported}
                                            alt="SportedTalks"
                                            className="bmwLogo"
                                        />
                                        <div className="position-relative">
                                            <img
                                                src={dots}
                                                alt="dots"
                                                className="dots2"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <div className={"page-slide"}>
                    <VirgilLogo/>

                    <div className="about pt-13vh">
                        <section
                            className="main-content pt-10 ">
                            <div className="container">
                                <div className="row position-relative">
                                    <div className="col-lg-2 col-sm-0"></div>

                                    <div className="col-lg-10 col-sm-12">
                                        <h1 className="text-uppercase pb-5 mb-0 text-left custom-title ft-wt-600">
                                            {t("stanescu.slide6.title")}
                                        </h1>
                                    </div>
                                    <div className="col-lg-2 col-sm-0"></div>

                                    <div className="col-lg-6 col-sm-8 p-font text-justified">
                                        <p>
                                            {t("stanescu.slide6.text1")}
                                        </p>
                                        <p>
                                            {t("stanescu.slide6.text2")}
                                        </p>
                                    </div>
                                    <div className="col-lg-4">
                                        <img
                                            src={speaker}
                                            alt="Speaker"
                                            className="bmwLogo"
                                        />
                                    </div>
                                    {/* <div className="d-lg-block letter2">S</div> */}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VirgilStanescu;
