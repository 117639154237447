import {Link} from "react-router-dom";
import "../css/home.css";

function Home()
{
    document.body.style = "background-color: #1e1e1e;";
    return (
        <div className="homePage home">
            <div className="container">
                <div className="row">
                    <Link to="/VirgilStanescu">
                        <h1>Virgil Stănescu</h1>
                    </Link>
                </div>

                <div className="row">
                    <Link to="/CristinaNeagu">
                        <h1>Cristina Neagu</h1>
                    </Link>
                </div>

                <div className="row">
                    <Link to="/AnaMariaBranza">
                        <h1>Ana-Maria Brânză</h1>
                    </Link>
                </div>

                <div className="row">
                    <Link to="/MihaiLeu">
                        <h1>Mihai Leu</h1>
                    </Link>
                </div>
            </div>
        </div>


    );
}

export default Home;
