import virgilL from "../img/VirgilLogo.svg";

function VirgilLogo()
{
    return (
        <div className="virgilLogo">
            <div className="firstLine"></div>
            <a href="http://frb.virgilstanescu.ro/" target="_blank" rel="noreferrer">
                <img src={virgilL} alt="virgil." className="virgilLogoName"/>
            </a>
            <div className="secondLine"></div>
        </div>
    );
}

export default VirgilLogo;
