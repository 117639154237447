import {FullPage, Slide} from "react-full-page";
import laur from "../img/laur 2.png";
import figure1 from "../img/anaMariaBranza.png";
import "../css/sport.css";
/*import flag from "../img/flag.svg";
import circles from "../img/circles.svg";
import cup from "../img/cup.png";*/
import dots from "../img/Dots.svg";
import quotation from "../img/QuotationBranza.svg";
import line from "../img/line.png";

import autentica from "../img/autentica.jpg";
import branza1 from "../img/branza1.jpg";
import branza2 from "../img/branza2.webp";
import superbet from "../img/Superbet-logo.jpg";
import ambitious from "../img/AMbitious.png";
import LogoSportiv from "./LogoSportiv";

function AnaMariaBranza()
{
    return (
        <div className="home branza">
            <FullPage>
                <Slide>
                    <section
                        className="container-fluid main-container container-home p-0 revealator-slideup revealator-once revealator-delay1">
                        <div className="color-block position-absolute d-none d-lg-block"></div>
                        <div className="row home-details-container align-items-center">
                            <div className="d-lg-block letter d-none">B</div>
                            <img src={laur} className="laur" alt="laur"/>
                            <div className="home-details text-left text-sm-center text-lg-left">
                                <div>
                                    <h1 className="text-uppercase vTitle">
                                        Ana-Maria Brânză
                                    </h1>
                                    {/* <div className="basketBall"></div> */}
                                    <img
                                        src={figure1}
                                        className="branzaFigure"
                                        alt="basketballPlayer"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                </Slide>
                <Slide>
                    <LogoSportiv image={ambitious} link={"https://www.anamariabranza.ro/"}/>

                    <div className="about pt-13vh">
                        <section className="main-content pt-10 revealator-slideup revealator-once revealator-delay1">
                            <div className="container">
                                <div className="row position-relative">
                                    <div className="col-12">
                                        <h1 className="text-uppercase pb-5 mb-0 text-right custom-title ft-wt-600">
                                            Walk Tall
                                        </h1>
                                    </div>
                                    <div className="col-lg-1"></div>

                                    <div className="col-lg-6 p-font text-justified">
                                        <p>
                                            La 10 ani, Ana Maria Brânză a
                                            încercat tenisul de câmp, însă nu
                                            i-a plăcut. Apoi a descoperit
                                            scrima, prin intermediul fratelui
                                            său mai mare, care juca fotbal la
                                            secția de juniori a CSA Steaua.
                                            Dragostea Anei pentru scrimă a luat
                                            naștere în Sala de scrimă a
                                            Complexului Sportiv Ghencea.
                                        </p>
                                        <p>
                                            Și-a început cariera la CSS nr. 3
                                            Steaua sub îndrumarea antrenorului
                                            Radu Szilaghy și a ales spada,
                                            pentru că era singura armă din sală
                                            cu mâner pentru stângaci.
                                        </p>
                                    </div>
                                    <div className="col-lg-1"></div>

                                    <div className="col-lg-4">
                                        <img
                                            src={branza1}
                                            alt="Branza"
                                            className="bmwLogo"
                                        />
                                        <div className="position-relative">
                                            <img
                                                src={dots}
                                                alt="dots"
                                                className="dots"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Slide>
                <Slide>
                    <LogoSportiv image={ambitious} link={"https://www.anamariabranza.ro/"}/>

                    <div className="blog-post pt-13vh about">
                        <section className="revealator-slideup revealator-once revealator-delay1">
                            <div className="container">
                                <div className="row">
                                    <article className="col-12 ">
                                        <h1>ÎN SPORT NU EXISTĂ SCURTĂTURI!</h1>
                                        <div className="position-relative size-0">
                                            <img
                                                src={quotation}
                                                alt="quotation"
                                                className="quotation"
                                            />
                                        </div>
                                        {/*<div className="circlesContainer">
                                            <img
                                                src={circles}
                                                className="circles"
                                                alt="Circles"
                                            />
                                        </div>*/}
                                        <div className="blog-except pb-5 p-font text-justified">
                                            <p>
                                                Ana Maria Brânză a fost
                                                declarată de 3 ori cea mai bună
                                                spadasină a lumii și de peste 15
                                                ani este liderul echipei de
                                                spadă feminin a României care a
                                                obținut medalii europene,
                                                mondiale, dar și aurul la
                                                Jocurile Olimpice de la Rio de
                                                Janeiro. Este o prezență
                                                jovială, elegantă și tenace,
                                                este mereu cu un pas înainte.
                                            </p>
                                            <p>
                                                A revenit pe planșă în toamna
                                                anului 2017, după o pauză de
                                                câteva luni și în vara imediat
                                                următoare a reușit să câștige
                                                medalia de argint la Campionatul
                                                Mondial. Ambițiile și planurile
                                                ei de viitor nu se opresc aici,
                                                obiectivul principal fiind
                                                Jocurile Olimpice de la Tokyo.
                                            </p>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="box-stats with-margin">
                                                    <h3 className="position-relative">
                                                        28k
                                                    </h3>
                                                    <p className="open-sans-font m-0 position-relative text-uppercase">
                                                        Likes
                                                        <span className="d-block">
                                                            on Facebook
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="box-stats with-margin">
                                                    <h3 className="position-relative">
                                                        12.4k
                                                    </h3>
                                                    <p className="open-sans-font m-0 position-relative text-uppercase">
                                                        Instagram
                                                        <span className="d-block">
                                                            followers
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </section>
                    </div>
                </Slide>
                <Slide>
                    <LogoSportiv image={ambitious} link={"https://www.anamariabranza.ro/"}/>

                    <div className="blog-post pt-13vh about">
                        <section className="revealator-slideup revealator-once revealator-delay1">
                            <div className="container">
                                <div className="row">
                                    {/*<div className="flagContainer">
                                        <img
                                            src={cup}
                                            alt="cup"
                                            className="cup"
                                        />
                                    </div>*/}
                                    <div className="col-12">
                                        <h1 className="text-uppercase pb-5 mb-0 text-sm-center custom-title ft-wt-600">
                                            PALMARES
                                        </h1>
                                    </div>
                                    <div className="col-lg-4"></div>
                                    <div className="col-lg-6 m-15px-tb">
                                        <div className="resume-box ">
                                            <ul>
                                                <li>
                                                    <div className="icon">
                                                        <i className="fas fa-award"></i>
                                                    </div>
                                                    <h5 className="text-uppercase">
                                                        2x Medalii Olimpice
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </h5>
                                                </li>
                                                <li>
                                                    <div className="icon">
                                                        <i className="fas fa-award"></i>
                                                    </div>
                                                    <h5 className="text-uppercase">
                                                        12x Medalii Campionate
                                                        Mondiale
                                                    </h5>
                                                </li>
                                                <li>
                                                    <div className="icon">
                                                        <i className="fas fa-award"></i>
                                                    </div>
                                                    <h5 className="text-uppercase">
                                                        11x Medalii Campionate
                                                        Europene
                                                    </h5>
                                                </li>
                                                <li>
                                                    <div className="icon">
                                                        <i className="fas fa-award"></i>
                                                    </div>
                                                    <h5 className="text-uppercase">
                                                        24x Medalii Cupe
                                                        Mondiale
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </h5>
                                                </li>
                                                <li>
                                                    <div className="icon">
                                                        <i className="fas fa-award"></i>
                                                    </div>
                                                    <h5 className=" text-uppercase">
                                                        17x Medalii Grand Prix
                                                    </h5>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Slide>
                <Slide>
                    <LogoSportiv image={ambitious} link={"https://www.anamariabranza.ro/"}/>

                    <div className="about pt-13vh">
                        <section className="main-content pt-10 revealator-slideup revealator-once revealator-delay1">
                            <div className="container">
                                <div className="row position-relative">
                                    <div className="col-12">
                                        <h1 className="text-uppercase mb-0 text-left custom-title ft-wt-600"
                                            style={{display: "inline-block"}}>
                                            Autentica
                                        </h1>
                                    </div>
                                    <div className="col-lg-7 p-font text-justified">
                                        <p>
                                            <span>
                                                „Autentica – Avem grijă de
                                                valorile noastre”
                                            </span>{" "}
                                            a fost proiectul cu care Ana-Maria
                                            Brânză a promovat România peste tot
                                            în lume. Pasionată de lumea
                                            fascinantă a atelierelor de creație,
                                            dar și de tradițiile și valorile
                                            românești, Ana-Maria Brânză și-a
                                            dorit să arate lumii ce înseamnă
                                            munca din spatele acestor produse.
                                            Proiectul a adunat creațiile a 100
                                            de designeri români, talentați și
                                            dedicați. Ana i-a vizitat pe fiecare
                                            în parte, le-a aflat poveștile și
                                            i-a adus în fața publicului ei.
                                        </p>
                                        <p>
                                            “Aș vrea să-i cunoașteți și voi pe
                                            fiecare, să-i înțelegeți, să le
                                            aflați povestea, să pătrundeți în
                                            universul artistic al fiecăruia în
                                            parte. Am pornit cu gândul la câțiva
                                            pe care-i îndrăgesc, apoi pasiunea
                                            mea pentru fashionul românesc de
                                            inspirație autentică a descoperit
                                            noi și noi nume. Treptat, din ceea
                                            ce mi-am dorit a fi un exercițiu de
                                            admirație, Autentica s-a transformat
                                            într-o poveste, într-o celebrare a
                                            spiritului autentic românesc,
                                            transpus atât de frumos și de
                                            original în ceea ce am putea numi,
                                            cu adevărat, artă.”
                                        </p>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="position-relative containerAutentica">
                                            <img
                                                src={autentica}
                                                alt="Autentica"
                                                className="bmwLogo mobileHide autentica"
                                            />
                                            <p className="autenticaText">
                                                <a
                                                    href="https://www.anamariabranza.ro/autentica/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="btn linkButton"
                                                >
                                                    <i className="fas fa-link"/> Către pagină
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="d-lg-block letter2">A</div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Slide>
                <Slide>
                    <LogoSportiv image={ambitious} link={"https://www.anamariabranza.ro/"}/>

                    <div className="about pt-13vh">
                        <section className="main-content pt-10 revealator-slideup revealator-once revealator-delay1">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <h1 className="text-uppercase pb-5 mb-0 text-right custom-title ft-wt-600">
                                            Brand Ambassador
                                        </h1>
                                    </div>
                                    {/*<div className="lineContainer">
                                        <img
                                            src={line}
                                            className="line"
                                            alt="line"
                                        />
                                    </div>*/}
                                    {/*<div className="flagContainer">
                                        <img
                                            src={flag}
                                            alt="Flag"
                                            className="flag"
                                        />
                                    </div>*/}
                                    <div className="col-lg-12 text-right p-font">
                                        <p className="text-justified">
                                            Dincolo de titlurile și medaliile
                                            din sport, Ana este o voce puternică
                                            a momentului, una care a dovedit
                                            deja că are un mesaj de transmis
                                            pentru fiecare generație, dar în
                                            special pentru cei mai tineri:
                                            fiecare reușită este precedată de
                                            mii și mii de ore de muncă, pentru
                                            că doar visele nu sunt suficiente
                                            pentru a ajunge campion.
                                        </p>
                                        <p className="text-justified">
                                            Ana Maria Brânză are calitățile unui
                                            speaker pe care vrei să-l
                                            (re)asculți indiferent de eveniment
                                            sau scenă, fie că vorbește despre
                                            sacrificiile făcute de copilul
                                            plecat prea devreme de acasă sau
                                            despre marile reușite ale carierei
                                            sale, despre colege, adversare sau
                                            antrenori, despre prieteni și
                                            familie sau despre marile și micile
                                            sale pasiuni.
                                        </p>

                                        <img
                                            src={branza2}
                                            alt="Steaua"
                                            className="bmwLogo"
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Slide>
                <Slide>
                    <LogoSportiv image={ambitious} link={"https://www.anamariabranza.ro/"}/>

                    <div className="about pt-13vh">
                        <section className="main-content pt-10 revealator-slideup revealator-once revealator-delay1">
                            <div className="container">
                                <div className="row position-relative">
                                    <div className="col-12">
                                        <h1 className="text-uppercase pb-5 mb-0 text-left custom-title ft-wt-600">
                                            Brand ambassador
                                        </h1>
                                    </div>
                                    <div className="col-lg-8 p-font text-justified">
                                        <p>
                                            Fiind o promotoare activă a
                                            sportului în rândul tinerilor,
                                            participă frecvent la evenimente
                                            destinate acestora, și de fiecare
                                            dată reușește cu succes să-i
                                            magnetizeze, să-și transpună
                                            propriile experiențe într-o poveste
                                            care să-i sensibilizeze, să-i
                                            ghideze și să-i ajute să-și
                                            descopere vocația și motivația în
                                            viață, indiferent de domeniul care-i
                                            pasionează.
                                        </p>
                                        <p>
                                            Pentru ea, sportul e mult mai mult
                                            decât o activitate fizică, iar
                                            scrima e mai mult decât un job.
                                            Sportul înseamnă disciplină,
                                            intuiție, antrenament, ambiție și
                                            responsabilitate. Atât la nivel
                                            fizic, cât mai ales psihic. Iar ea
                                            știe cel mai bine cum se contruiește
                                            și călește caracterul unui campion,
                                            așa că vorbește cu pasiune și
                                            responsabilitate despre toate
                                            experiențele care au transformat-o
                                            în ceea ce este: The Fencing Queen,
                                            cum îi spun cei apropiați.
                                        </p>
                                    </div>
                                    <div className="col-lg-4">
                                        <img
                                            src={superbet}
                                            alt="Superbet"
                                            className="bmwLogo partners"
                                            style={{maxWidth: "calc(10vw + 19vh)"}}
                                        />
                                    </div>
                                    {/* <div className="d-lg-block letter2">S</div> */}
                                </div>
                            </div>
                        </section>
                    </div>
                </Slide>
            </FullPage>
        </div>
    );
}

export default AnaMariaBranza;
