import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Stanescu from "./components/VirgilStanescu";
import Neagu from "./components/CristinaNeagu";
import Branza from "./components/AnaMariaBranza";
import Potec from "./components/CameliaPotec";
import Home from "./components/Home";
import Header from "./components/Header";

import Leu from "./components/MihaiLeu";
import Beleaga from "./components/GianinaBeleaga";
import Cozmiuc from "./components/IonelaLiviaCozmiuc";
import HeaderV2 from "./components/HeaderV2";
import HeaderMobile from "./components/Headermobile";

function App()
{
    /*window.addEventListener("keydown", function (e)
    {
        if (["Space", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].indexOf(e.code) > -1)
        {
            e.preventDefault();
        }
    }, false);*/

    return (
        <Router forceRefresh={true}>
            <div className="row">
                <div className="col-lg-10 col-md-12">
                    <Switch>
                        <Route path="/" exact component={Home}/>
                        <Route path="/VirgilStanescu" component={Stanescu}/>
                        <Route path="/CristinaNeagu" component={Neagu}/>
                        <Route path="/AnaMariaBranza" component={Branza}/>
                        <Route path="/CameliaPotec" component={Potec}/>
                        <Route path="/MihaiLeu" component={Leu}/>
                        <Route path="/GianinaBeleaga" component={Beleaga}/>
                        <Route path="/IonelaLiviaCozmiuc" component={Cozmiuc}/>
                    </Switch>
                </div>

                <div className="col-lg-2 col-md-0 mobileHide">
                    <HeaderV2/>
                </div>

                <HeaderMobile/>
            </div>
        </Router>
    );
}

export default App;
