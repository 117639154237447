const LogoSportiv = (props) =>
{
    return (
        <div className="logoSportiv revealator-slideup revealator-once revealator-delay1">
            <a href={props.link} target="_blank" rel="noreferrer">
                <img src={props.image} alt="Logo Sportiv"/>
            </a>
        </div>
    );
};

export default LogoSportiv;