import {FullPage, Slide} from "react-full-page";
import neagu1 from "../img/cristinaNeagu1.jpg";
import figure1 from "../img/cristinaNeagu.png";
import laur from "../img/laur4-black.png";
import neagu2 from "../img/cristinaNeagu2.jpg";
import dots from "../img/Dots.svg";
import quotation from "../img/QuotationNeagu.svg";
import redBull from "../img/RedBull.svg";
import dbSchenker from "../img/dbSchenker.png";
import kaufland from "../img/kaufland.png";
/*import flag from "../img/flag.svg";
import circles from "../img/circles.svg";
import cup from "../img/cup.png";*/
import "../css/sport.css";
import cristinaNeaguLogo from "../img/cristinaNeaguLogo.png";
import LogoSportiv from "./LogoSportiv";

function CristinaNeagu()
{
    return (
        <div className="home neagu">
            <FullPage>
                <Slide>
                    <section
                        className="container-fluid main-container container-home p-0 revealator-slideup revealator-once revealator-delay1">
                        <div className="color-block position-absolute d-none d-lg-block"></div>
                        <div className="row home-details-container align-items-center">
                            <div className="d-lg-block letter d-none">N</div>
                            <img src={laur} className="laur" alt="laur"/>
                            <div className="home-details text-left text-sm-center text-lg-left">
                                <div>
                                    <h1 className="text-uppercase  vTitle">
                                        Cristina Neagu
                                    </h1>
                                    {/* <div className="basketBall"></div> */}
                                    <img
                                        src={figure1}
                                        className="cristinaNeaguFigure"
                                        alt="basketballPlayer"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                </Slide>
                <Slide>
                    <LogoSportiv image={cristinaNeaguLogo} link="https://cristina-neagu.ro/"/>

                    <div className="about pt-13vh">
                        <section className="main-content pt-10 revealator-slideup revealator-once revealator-delay1">
                            <div className="container">
                                <div className="row position-relative">
                                    <div className="col-12">
                                        <h1 className="text-uppercase pb-5 mb-0 text-right custom-title ft-wt-600">
                                            Walk Tall
                                        </h1>
                                    </div>
                                    <div className="col-lg-1"></div>

                                    <div className="col-lg-6 p-font text-justified">
                                        <p>
                                            Cristina Neagu este singura
                                            jucătoare de handbal din istorie
                                            care a câștigat de patru ori premiul
                                            de ”Cea mai bună jucătoare din lume”
                                            în 2010, 2015, 2016 și 2018,
                                            distincție oferită de către
                                            Federația Internațională de Handbal.
                                        </p>
                                        <p>
                                            Din copilărie, de la fotbalul jucat
                                            cu băieții din Ghencea și până la
                                            întrecerile pe care le organiza cu
                                            prietenele ei, totul trebuia să fie
                                            o provocare din care să poată ieși
                                            învingătoare. Aceste calități s-au
                                            tradus în cariera ei sportivă –
                                            Cristina impresionează prin
                                            determinare și disciplină, prin
                                            munca depusă și prin calitățile de
                                            lider.
                                        </p>
                                    </div>
                                    <div className="col-lg-1"></div>

                                    <div className="col-lg-4">
                                        <img
                                            src={neagu1}
                                            alt="Cristina Neagu"
                                            className="bmwLogo"
                                        />
                                        <div className="position-relative">
                                            <img
                                                src={dots}
                                                alt="dots"
                                                className="dots"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Slide>
                <Slide>
                    <LogoSportiv image={cristinaNeaguLogo} link="https://cristina-neagu.ro/"/>

                    <div className="blog-post pt-13vh about">
                        <section className="revealator-slideup revealator-once revealator-delay1">
                            <div className="container">
                                <div className="row">
                                    <article className="col-12 ">
                                        <h1>ÎN SPORT NU EXISTĂ SCURTĂTURI!</h1>
                                        <div className="position-relative size-0">
                                            <img
                                                src={quotation}
                                                alt="quotation"
                                                className="quotation"
                                            />
                                        </div>

                                        <div className="blog-except pb-5 p-font text-justified">
                                            <p>
                                                După două reveniri, prima după
                                                aproape trei ani de accidentări
                                                și repaus, a doua, în 2019, după
                                                10 luni în afara terenului și
                                                obținând an după an,
                                                nominalizări și premii, este
                                                clar că performanțele Cristinei
                                                nu sunt întâmplătoare, că sunt
                                                rodul unor eforturi deosebite,
                                                constante, și a unei mentalități
                                                cultivate în ani de muncă.
                                            </p>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="box-stats with-margin">
                                                    <h3 className=" position-relative">
                                                        177k
                                                    </h3>
                                                    <p className="open-sans-font m-0 position-relative text-uppercase">
                                                        Likes
                                                        <span className="d-block">
                                                            on Facebook
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="box-stats with-margin">
                                                    <h3 className=" position-relative">
                                                        89.9k
                                                    </h3>
                                                    <p className="open-sans-font m-0 position-relative text-uppercase">
                                                        Instagram
                                                        <span className="d-block">
                                                            followers
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </section>
                    </div>
                </Slide>
                <Slide>
                    <LogoSportiv image={cristinaNeaguLogo} link="https://cristina-neagu.ro/"/>

                    <div className="blog-post pt-13vh about ">
                        <section className="revealator-slideup revealator-once revealator-delay1">
                            <div className="container">
                                <div className="row">
                                    {/*<div className="flagContainer">
                                        <img
                                            src={flag}
                                            alt="Flag"
                                            className="flag"
                                        />
                                        <img
                                            src={cup}
                                            alt="cup"
                                            className="cup"
                                        />
                                    </div>*/}
                                    <div className="col-12">
                                        <h1 className="text-uppercase pb-5 mb-0 text-left text-sm-center custom-title ft-wt-600">
                                            PALMARES
                                        </h1>
                                    </div>
                                    <div className="col-lg-6 m-15px-tb ">
                                        <div className="resume-box">
                                            <ul>
                                                <li>
                                                    <div className="icon">
                                                        <i className="fas fa-award"></i>
                                                    </div>
                                                    <h5 className=" text-uppercase">
                                                        4x Cea mai bună
                                                        jucătoare de handbal din
                                                        lume
                                                    </h5>
                                                    {/* <p className="open-sans-font"></p> */}
                                                </li>
                                                <li>
                                                    <div className="icon">
                                                        <i className="fas fa-award"></i>
                                                    </div>
                                                    <h5 className=" text-uppercase">
                                                        6x Cea mai bună
                                                        jucătoare de handbal din
                                                        România
                                                    </h5>
                                                </li>
                                                <li>
                                                    <div className="icon">
                                                        <i className="fas fa-award"></i>
                                                    </div>
                                                    <h5 className=" text-uppercase">
                                                        3x Interul stânga al
                                                        echipei ideale
                                                        Campionatul European
                                                    </h5>
                                                </li>
                                                <li>
                                                    <div className="icon">
                                                        <i className="fas fa-award"></i>
                                                    </div>
                                                    <h5 className=" text-uppercase">
                                                        1x Câștigătoare Liga
                                                        Campionilor EHF
                                                    </h5>
                                                </li>
                                                <li>
                                                    <div className="icon">
                                                        <i className="fas fa-award"></i>
                                                    </div>
                                                    <h5 className=" text-uppercase">
                                                        4x Interul stânga al
                                                        echipei ideale Liga
                                                        Campionilor
                                                    </h5>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 m-15px-tb">
                                        <div className="resume-box">
                                            <ul>
                                                <li>
                                                    <div className="icon">
                                                        <i className="fas fa-award"></i>
                                                    </div>
                                                    <h5 className=" text-uppercase">
                                                        6x Campioana Ligii
                                                        Naționale a României
                                                    </h5>
                                                </li>
                                                <li>
                                                    <div className="icon">
                                                        <i className="fas fa-award"></i>
                                                    </div>
                                                    <h5 className=" text-uppercase">
                                                        2x Medalii de bronz cu
                                                        echipa națională CE + CM
                                                    </h5>
                                                </li>
                                                <li>
                                                    <div className="icon">
                                                        <i className="fas fa-award"></i>
                                                    </div>
                                                    <h5 className=" text-uppercase">
                                                        1x Cea mai bună
                                                        jucătoare (MVP)
                                                        Campionatul Mondial
                                                    </h5>
                                                </li>
                                                <li>
                                                    <div className="icon">
                                                        <i className="fas fa-award"></i>
                                                    </div>
                                                    <h5 className=" text-uppercase">
                                                        2x Cea mai bună
                                                        jucătoare (MVP) Cupa
                                                        Mondială
                                                    </h5>
                                                </li>
                                                <li>
                                                    <div className="icon">
                                                        <i className="fas fa-award"></i>
                                                    </div>
                                                    <h5 className=" text-uppercase">
                                                        4x Golgheter Liga
                                                        Campionilor + CE + CM
                                                    </h5>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Slide>

                <Slide>
                    <LogoSportiv image={cristinaNeaguLogo} link="https://cristina-neagu.ro/"/>

                    <div className="about pt-13vh">
                        <section className="main-content pt-10 revealator-slideup revealator-once revealator-delay1">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <h1 className="text-uppercase pb-5 mb-0 text-right custom-title ft-wt-600">
                                            Brand Ambassador
                                        </h1>
                                    </div>
                                    <div className="col-lg-12 text-justified p-font">
                                        <p>
                                            De ce ai alege o colaborare cu un
                                            sportiv pentru un proiect special
                                            sau poate chiar pentru rolul de
                                            brand ambassador? Sau mai bine zis,
                                            de ce ai alege-o pe Cristina Neagu?
                                        </p>
                                        <p>
                                            Un sportiv în povestea ta e exact
                                            acel erou pe care-l cauți, cu
                                            speranța că va cuceri inimile
                                            clienților și partenerilor tăi de
                                            business. Unul în carne și oase, cu
                                            o istorie autentică în spate, care
                                            poate fi verificată, cu performanțe
                                            recunoscute și premiate, cu
                                            notorietate și milioane de fani și
                                            admiratori.
                                        </p>
                                        <p>
                                            Iar dacă printre valorile brandului
                                            tău se numără respectul pentru muncă
                                            și performanță, excelența și
                                            ambiția, mentalitatea de
                                            învingătoare, dar și căldura umană
                                            și bunul simț, atunci ai ajuns la
                                            locul potrivit, pe pagina Cristinei
                                            Neagu.
                                        </p>
                                    </div>
                                    <div className="col-lg-12 text-right">
                                        <img
                                            src={neagu2}
                                            alt="Steaua"
                                            className="bmwLogo"
                                            id="neagu2"
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Slide>

                <Slide>
                    <LogoSportiv image={cristinaNeaguLogo} link="https://cristina-neagu.ro/"/>

                    <div className="about pt-13vh">
                        <section className="main-content pt-10 revealator-slideup revealator-once revealator-delay1">
                            <div className="container">
                                <div className="row parteneriate">
                                    <div className="col-12 ">
                                        <h1 className="text-uppercase text-center pb mb-0 custom-title ft-wt-600"
                                            style={{paddingBottom: "60px"}}>
                                            Parteneriate
                                        </h1>
                                    </div>
                                    <div className="col-lg-4 p-font pb">
                                        <img
                                            src={redBull}
                                            alt="Steaua"
                                            className="partners"
                                        />
                                    </div>

                                    <div className="col-lg-4 p-font pb">
                                        <img
                                            src={kaufland}
                                            alt="Steaua"
                                            className="partners"
                                        />
                                    </div>

                                    <div className="col-lg-4 p-font ">
                                        <img
                                            src={dbSchenker}
                                            alt="Steaua"
                                            className="partners"
                                            style={{minWidth: "380px"}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Slide>
            </FullPage>
        </div>
    );
}

export default CristinaNeagu;
