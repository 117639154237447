import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const HeaderMobile = () =>
{
    const {t, i18n} = useTranslation();

    return (
        <header className="header" id="navbar-collapse-toggle">
            <nav role="navigation" className="d-block d-lg-none">
                <div id="menuToggle">
                    <input className={"menuButton"} type="checkbox"/>
                    <span></span>
                    <span></span>
                    <span></span>
                    <ul className="list-unstyled menu-mobile" id="menu">
                        <li>
                            <Link to="/">
                                <span>{t("menu.m1")}</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/">
                                <span>{t("menu.m2")}</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/">
                                <span>{t("menu.m3")}</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/">
                                <span>{t("menu.m4")}</span>
                            </Link>
                        </li>

                        <li>
                            <div className={"mobileLngSwitchContainer"}>
                                <span className={"sw-lng"}>RO </span>
                                <label className="switch" htmlFor={"lng"}>
                                    <input type="checkbox" id={"lng"} name={"lng"}
                                           onClick={() => i18n.changeLanguage(i18n.resolvedLanguage === "en" ? "ro" : "en")}
                                           checked={i18n.resolvedLanguage === "en"}/>
                                    <span className="slider round"></span>
                                </label>
                                <span className={"sw-lng"}> EN</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>

    );
};

export default HeaderMobile;
