import {FullPage, Slide} from "react-full-page";
import laur from "../img/laur 2.png";
import figure1 from "../img/leu2.png";
import "../css/sport.css";
/*import flag from "../img/flag.svg";
import circles from "../img/circles.svg";
import cup from "../img/cup.png";*/
import dots from "../img/Dots.svg";
import quotation from "../img/QuotationLeu.svg";
import leu3 from "../img/leu3.jpg";
import unicredit from "../img/unicredit.jpg";
import superbet from "../img/Superbet-logo.jpg";
import omniasig from "../img/omniasig.png";
import romgaz from "../img/romgaz.png";
import leuLogo from "../img/leuLogo.png";
import LogoSportiv from "./LogoSportiv";

function MihaiLeu()
{
    return (
        <div className="home leu">
            <FullPage>
                <Slide>
                    <section
                        className="container-fluid main-container container-home p-0 revealator-slideup revealator-once revealator-delay1">
                        <div className="color-block position-absolute d-none d-lg-block"></div>
                        <div className="row home-details-container align-items-center">
                            <div className="d-lg-block letter d-none">L</div>
                            <img src={laur} className="laur" alt="laur"/>
                            <div className="home-details text-left text-sm-center text-lg-left">
                                <div>
                                    <h1 className="text-uppercase  vTitle">
                                        Mihai Leu
                                    </h1>
                                    {/* <div className="basketBall"></div> */}
                                    <img
                                        src={figure1}
                                        className="figure"
                                        alt="Leu"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                </Slide>
                <Slide>
                    <LogoSportiv image={leuLogo}/>

                    <div className="about pt-13vh">
                        <section className="main-content pt-10 revealator-slideup revealator-once revealator-delay1">
                            <div className="container">
                                <div className="row position-relative">
                                    <div className="col-12">
                                        <h1 className="text-uppercase pb-5 mb-0 text-right custom-title ft-wt-600">
                                            Walk Tall
                                        </h1>
                                    </div>
                                    {/*<div className="flagContainer">
                                        <img
                                            src={flag}
                                            alt="Flag"
                                            className="flag"
                                        />
                                    </div>*/}
                                    <div className="col-lg-1"></div>

                                    <div className="col-lg-6 p-font text-justified">
                                        <p>
                                            Într-o societate obișnuită cu
                                            resemnarea, Mihai Leu a apărut în
                                            conștiința românilor drept eroul
                                            care crede în destinul său de
                                            învingător. Însumate, victoriile
                                            sale pe ringurile profesioniste de
                                            box au însemnat în fapt și o imensă
                                            șansă oferită pugilismului din
                                            România de a pătrunde din nou într-o
                                            lume selectă.
                                        </p>
                                        <p>
                                            Pe poarta deschisă de Mihai Leu au
                                            intrat apoi mult mai uțor nume ca
                                            Leonard Doroftei, Adrian Diaconu sau
                                            Lucian Bute.
                                        </p>
                                    </div>
                                    <div className="col-lg-1"></div>

                                    <div className="col-lg-4">
                                        <img
                                            src={leu3}
                                            alt="Leu"
                                            className="bmwLogo"
                                        />
                                        <div className="position-relative">
                                            <img
                                                src={dots}
                                                alt="dots"
                                                className="dots"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Slide>
                <Slide>
                    <LogoSportiv image={leuLogo}/>

                    <div className="blog-post pt-13vh about">
                        <section className="revealator-slideup revealator-once revealator-delay1">
                            <div className="container">
                                <div className="row">
                                    <article className="col-12 ">
                                        <h1>ÎN SPORT NU EXISTĂ SCURTĂTURI!</h1>
                                        <div className="position-relative size-0">
                                            <img
                                                src={quotation}
                                                alt="quotation"
                                                className="quotation"
                                            />
                                        </div>
                                        {/*<div className="circlesContainer">
                                            <img
                                                src={circles}
                                                className="circles"
                                                alt="Circles"
                                            />
                                        </div>*/}
                                        <div className="blog-except pb-5 p-font text-justified">
                                            <p>
                                                Obligat să abandoneze boxul din
                                                cauza unei accidentari, Mihai
                                                Leu a simțit că mai are multe de
                                                oferit sportului și a trecut la
                                                automobilism. Obișnuit cu
                                                provocările, Leu a pornit pe un
                                                drum anevoios cu încrederea dată
                                                de instinctul său de învingător.
                                            </p>
                                            <p>
                                                N-a dorit să fie doar pilot, ci
                                                omul care poate să-și conducă
                                                visul de a fi campion spre
                                                realitate. A reușit să devină
                                                pentru prima oara campion
                                                național în 2003, după cinci
                                                sezoane în Campionatul Național
                                                de Raliuri, iar în 2019, a
                                                reușit să revină și să fie din
                                                nou primul la clasa la care a
                                                participat.
                                            </p>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="box-stats with-margin">
                                                    <h3 className=" position-relative">
                                                        2.7k
                                                    </h3>
                                                    <p className="open-sans-font m-0 position-relative text-uppercase">
                                                        Likes
                                                        <span className="d-block">
                                                            on Facebook
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            {/* <div className="col-6">
                                                <div className="box-stats with-margin">
                                                    <h3 className=" position-relative">
                                                        12.4k
                                                    </h3>
                                                    <p className="open-sans-font m-0 position-relative text-uppercase">
                                                        Instagram
                                                        <span className="d-block">
                                                            followers
                                                        </span>
                                                    </p>
                                                </div>
                                            </div> */}
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </section>
                    </div>
                </Slide>
                <Slide>
                    <LogoSportiv image={leuLogo}/>

                    <div className="blog-post pt-13vh about">
                        <section className="revealator-slideup revealator-once revealator-delay1">
                            <div className="container">
                                <div className="row">
                                    {/*<div className="flagContainer">
                                        <img
                                            src={cup}
                                            alt="cup"
                                            className="cup"
                                        />
                                    </div>*/}
                                    <div className="col-12">
                                        <h1
                                            id="palmares"
                                            className="text-uppercase pb-5 mb-0 text-sm-center custom-title ft-wt-600"
                                        >
                                            PALMARES
                                        </h1>
                                    </div>

                                    <div className="col-lg-6 m-15px-tb">
                                        <div className="resume-box ">
                                            <h4 className="text-center">
                                                Raliuri
                                            </h4>
                                            <ul>
                                                <li>
                                                    <div className="icon">
                                                        <i className="fas fa-award"></i>
                                                    </div>
                                                    <h5 className=" text-uppercase">
                                                        Campion național absolut
                                                        la raliuri în 2003.
                                                    </h5>
                                                </li>
                                                <li>
                                                    <div className="icon">
                                                        <i className="fas fa-award"></i>
                                                    </div>
                                                    <h5 className=" text-uppercase">
                                                        Campion național în 2019
                                                        în divizia dedicată
                                                        mașinilor de producție
                                                        din cadrul campionatului
                                                        de viteză în coastă.
                                                    </h5>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 m-15px-tb">
                                        <div className="resume-box ">
                                            <h4 className="text-center">BOX</h4>
                                            <ul>
                                                <li>
                                                    <div className="icon">
                                                        <i className="fas fa-award"></i>
                                                    </div>
                                                    <h5 className=" text-uppercase">
                                                        La amatori a boxat în
                                                        200 de meciuri din care
                                                        a câștigat 190
                                                    </h5>
                                                </li>
                                                <li>
                                                    <div className="icon">
                                                        <i className="fas fa-award"></i>
                                                    </div>
                                                    <h5 className=" text-uppercase">
                                                        1983, 1984, 1985, 1986
                                                        Campion al României
                                                    </h5>
                                                </li>
                                                <li>
                                                    <div className="icon">
                                                        <i className="fas fa-award"></i>
                                                    </div>
                                                    <h5 className=" text-uppercase">
                                                        1987 Campion Mondial de
                                                        Juniori
                                                    </h5>
                                                </li>
                                                <li>
                                                    <div className="icon">
                                                        <i className="fas fa-award"></i>
                                                    </div>
                                                    <h5 className=" text-uppercase">
                                                        Din 1991 trece la
                                                        profesioniști, unde
                                                        boxează în 28 de meciuri
                                                        și câștigă 28.
                                                    </h5>
                                                </li>
                                                <li>
                                                    <div className="icon">
                                                        <i className="fas fa-award"></i>
                                                    </div>
                                                    <h5 className=" text-uppercase">
                                                        1993 Titlul
                                                        Intercontinental al
                                                        Germaniei (la care a
                                                        renunțat fără luptă)
                                                    </h5>
                                                </li>
                                                <li>
                                                    <div className="icon">
                                                        <i className="fas fa-award"></i>
                                                    </div>
                                                    <h5 className=" text-uppercase">
                                                        1995 Titlul
                                                        Intercontinental WBO (la
                                                        care a renunțat fără
                                                        luptă)
                                                    </h5>
                                                </li>
                                                <li>
                                                    <div className="icon">
                                                        <i className="fas fa-award"></i>
                                                    </div>
                                                    <h5 className=" text-uppercase">
                                                        1997 Campion Mondial (la
                                                        care a renunțat din
                                                        motive medicale)
                                                    </h5>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                    </div>
                </Slide>
                <Slide>
                    <LogoSportiv image={leuLogo}/>

                    <div className="about pt-13vh">
                        <section className="main-content pt-10 revealator-slideup revealator-once revealator-delay1">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 p-font">
                                        <h1 className="text-uppercase pb-5 mb-0 text-left custom-title ft-wt-600">
                                            Alte proiecte
                                        </h1>
                                        <p>
                                            AMC Racing cu care organizeaza Super
                                            Rally.
                                        </p>
                                        {/*<a
                                            href="https://www.facebook.com/cnsuperrally/"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="btn linkButton"
                                        >
                                            <i className="fas fa-link"/> Către pagină
                                        </a>*/}
                                    </div>
                                    <div
                                        className="col-lg-6 p-font"
                                        id="parteneri"
                                    >
                                        <h1 className="text-uppercase pb-5 mb-0 text-left custom-title ft-wt-600"
                                            style={{paddingBottom: "10px !important"}}>
                                            Parteneri
                                        </h1>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <img
                                                    src={unicredit}
                                                    alt="unicredit"
                                                    className="sponsor partners"
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <img
                                                    src={superbet}
                                                    alt="superbet"
                                                    className="sponsor partners"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <img
                                                    src={omniasig}
                                                    alt="unicredit"
                                                    className="sponsor partners"
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <img
                                                    src={romgaz}
                                                    alt="superbet"
                                                    className="sponsor partners"
                                                    id="romgaz"
                                                    style={{maxWidth: "6vw"}}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="position-relative">
                                        <div className="d-lg-block letter3">
                                            <p>L</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Slide>
            </FullPage>
        </div>
    );
}

export default MihaiLeu;
