import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'ro',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    menu: {
                        m1: "Athletes",
                        m2: "Services",
                        m3: "Projects",
                        m4: "Contact",
                    },

                    stanescu: {
                        slide1: {
                            title: "Walk tall",
                            text1: "Virgil Stănescu, is a former captain of the Romanian National Basketball Team, awarded five times as Romania's Player of the Year, with an impressive international career. He started basketball at the organized level in 1994 at SOCED Bucharest, receiving in 1998 a scholarship at the University of South Alabama. Returned to Europe in 2001 where he played in the EuroLeague, EuroCup and EuroChallenge, for top teams out of powerful leagues such as Germany, Italy, Russia, Turkey and Belgium. He was selected for 15 years as member of the Romanian national team, ten of which being the captain of the team. In 2014 he was inducted into the University of South Alabama Hall of Fame.",
                        },
                        slide2: {
                            title: "THERE ARE NO SHORTCUTS IN SPORT!",
                            text1: "After 20 years of sports career and countless trophies and medals, he retired from the life of a basketball player transitioning to sport management and taking over the role of President of the Steaua CSM EximBank basketball team, where, in addition to the growth of the senior team, he also laid the foundations of a center of \"excellence\" for youth, under whose umbrella played basketball approximately 1200 children.",
                            text2: "He believes in continuous education - he graduated in 2002 General management (Univ. of South Al.) in 2006 MS European Economical Integration and in 2019 both a FIBA management program and a master's in Leadership and Management at Northumbria University in Great Britain.",
                        },
                        slide3: {
                            title: "Steaua CSM Eximbank",
                            text1: "For 6 years (2012-2018), Virgil Stănescu led the basketball team Steaua CSM EximBank. During these years, the club managed not only to return to the first National Basketball League, but also to rank 2nd in two consecutive seasons and participate in European competitions. Moreover, Steaua CSM Eximbank developed together with its partners the largest program in Romania dedicated to youth, under which umbrella 1200 children were trained.",
                        },
                        slide4: {
                            title: "Sports HUB",
                            text1: "Founded in 2016, Sports HUB was created to connect two environments where until now, there were only sporadic interactions: sports and business. Both environments contribute significantly to the development of society and can be measured at least with financial KPIs and emotional KPIs. The synergy that those create can become a unique mix of marketing, with a return on investment to match. Also, Sports HUB aims to become a business and personal development community for both active and retired athletes who want to further invest their time, resources and knowledge in sports.",
                        },
                        slide5: {
                            title: "SportED Talks",
                            text1: "SportED Talks is a podcast where Virgil talks with people that had been influenced greatly by sports. Inviting athletes or former athletes, coaches, business people or just passionate people with strong sport connection, all sharing their journeys, experiences, learnings and how they were shaped personally and personally, influencing their present.",
                        },
                        slide6: {
                            title: "Speaker",
                            text1: "The idea of sharing the experience gained in sports with others, formally and conceptually, translating the attributes acquired and processes learned from sports field to business teams, to leadership programs or workshops applied to HR departments, came natural to Virgil. Talking about performance mindset, roles, success, focus on performance, leadership, coaching, individual and group performances from a sports perspective being able to create bridges in front of business teams, confirms every time how many similarities can be translated between the two and how much can be learned  through sports examples.",
                            text2: "\"Education in sports and outside of sports is important. I know every HR department puts a lot of effort into educate employees with soft-skills and attributes that sports can develop as early as 1st grade - the same school lesson that develops skills in grade 1. This non-formal education through sports can bring those social and conceptual lessons closer to every person.\"",
                        },
                    },
                },
            },
            ro: {
                translation: {
                    menu: {
                        m1: "Sportivi",
                        m2: "Servicii",
                        m3: "Proiecte",
                        m4: "Contact",
                    },

                    stanescu: {
                        slide1: {
                            title: "Walk tall",
                            text1: "Virgil Stănescu a fost căpitan al echipei naționale de baschet și de cinci ori cel mai bun jucător al României, cu o impresionantă carieră internațională. A început baschetul la nivel organizat în 1994 la SOCED București, iar în 1998 a primit o bursă în NCAA, liga universitară americană de la Universitatea din Alabama de Sud. A revenit apoi în Europa în 2001 și a jucat în Euroliga, EuroCup și EuroChallenge, la echipe de vârf din campionate puternice precum Germania, Italia, Rusia, Turcia și Belgia. A fost selecționat timp de 15 ani în echipa națională a României din care zece a fost și căpitan. În 2014 a fost inclus în Hall of Fame-ul Universității din Alabama de Sud.",
                        },
                        slide2: {
                            title: "ÎN SPORT NU EXISTĂ SCURTĂTURI!",
                            text1: "După 20 de ani de cariera sportivă și nenumarate trofee și medalii, a renunțat la viața de baschetbalist dar a rămas în sport preluând conducerea echipei de baschet Steaua CSM EximBank, unde pe langă creșterea echipei de seniori, a pus și bazele unui centru de “excelenta” pentru copii si tineret, sub umbrela caruia joaca baschet aproximativ 1200 de copii.",
                            text2: "Crede în educație și continuă să se dezvolte – a absolvit în 2001 Management General (Univ. of South Al.) in 2006 Master in Integrare Economica Europeana si in 2019 atât un program de management al FIBA cat si un master in Leadership și Management la Universitate Northumbria din Marea Britanie.",
                        },
                        slide3: {
                            title: "Steaua CSM Eximbank",
                            text1: "Timp de 6 ani, Virgil Stănescu a condus echipa de baschet a clubului Steaua. În acești ani, clubul a reușit nu doar revenirea în Liga Națională de baschet, dar și clasarea pe locul 2 în doua sezoane consecutive și participarea în competiții europe. Mai mult, Steaua CSM Eximbank a dezvoltat alaturi de partenerii săi cel mai mare program din România dedicat copiilor și juniorilor, sub umbrela căruia se pregăteau 1200 de copii.",
                        },
                        slide4: {
                            title: "Sports HUB",
                            text1: "Fondat în 2016, Sports HUB a fost creat pentru a uni două medii între care, până acum, au existat doar interacțiuni sporadice: sportul și business-ul. Ambele medii contribuie semnificativ la dezvoltarea societății și pot fi cuantificate din cel puțin doua puncte de vedere: financiar și emotional. Sinergia pe care ‘unirea’ acestora o creează poate deveni un mix de marketing și de suport de imagine unic, cu un randament al investiției pe măsură. De asemenea, Sports HUB își propune să devină o comunitate de business și dezvoltare personală atât pentru sportivii în activitate cât și pentru foști sportivi care doresc să investească în continuare în sport timpul, resursele și cunoștințele lor.",
                        },
                        slide5: {
                            title: "SportED Talks",
                            text1: "SportED Talks este un podcast în care Virgil discută deschis cu oameni care au fost influențați de sport. Sportivi sau foști sportivi, antrenori, oameni de afaceri sau doar oameni pasionați care au legături puternice cu acesta, toți își împărtășesc experiența de sport și felul în care aceasta i-a modelat și le-a influențat prezentul.",
                        },
                        slide6: {
                            title: "Speaker",
                            text1: "Ideea de a împarți din experiența dobândită în sport cu ceilalti, formal și aplicat, de a traduce atributele dobândite pe terenul de sport în limbaj de business, în programe de leadership sau workshop-uri aplicate departamentelor de HR, a venit organic în cariera lui Virgil. A vorbi despre mindset de performanta, roluri, success, focus pe performanta, leadership, coaching, performanțe individuale și de grup din perspectivă sportivă în fața unor performeri din business îi confirmă de fiecare dată cate similitudini pot fi tranduse intre cele doua industrii și cât de multe exemple pot fi aduse si invatate din sport.",
                            text2: "„Este importantă educaţia în sport şi în afara sportului. Ştiu că orice departament de HR se chinuie să educe angajaţii cu abiliăţi pe care sportul le poate dezvolta încă din clasa I - aceeaşi lecţie din şcoală care dezvoltă abilităţile din clasa I. Această educaţie nonformală prin sport aduce acele calităţi sociale în orice cetăţean.“",
                        },
                    },
                },
            },
        },
    });

export default i18n;
