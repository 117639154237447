import {Link} from "react-router-dom";
import {useLocation} from "react-router-dom";
import sportsHubLogo from "../img/sportsHubIcon.png";
import "../css/header.css";

function Header()
{
    const location = useLocation();

    return (
        <div className="header-bg">
            <header className="header mainLogo">
                <ul className="icon-menu d-none d-lg-block revealator-slideup revealator-once revealator-delay1">
                    <li className="icon-box sportsLogoContainer">
                        <Link to="/">
                            <img src={sportsHubLogo} alt={"SportsHubIcon"} className="sportsLogo"/>
                            <h2>Acasă</h2>
                        </Link>
                    </li>
                </ul>
            </header>

            <header className="header" id="navbar-collapse-toggle">
                <ul className="icon-menu d-none d-lg-block revealator-slideup revealator-once revealator-delay1">

                    <li className={location.pathname === "/" ? "active icon-box" : "icon-box"}>
                        <Link to="/">
                            <i className="fas fa-running"></i>
                            <h2>Sportivi</h2>
                        </Link>
                    </li>

                    <li className={location.pathname === "/servicii" ? "active icon-box" : "icon-box"}>
                        <Link to="/">
                            <i className="fas fa-briefcase"></i>
                            <h2>Servicii</h2>
                        </Link>
                    </li>

                    <li className="icon-box">
                        <Link to="/">
                            <i className="fas fa-users"></i>
                            <h2>Colorship</h2>
                        </Link>
                    </li>

                    {/*<li style={{listStyleType: "none"}}>&nbsp;</li>*/}


                    {/*todo add later*/}
                    {/*<li className="icon-box">
                        <i className="fas fa-shopping-cart"></i>
                        <a href="https://shop.sports-hub.ro/">
                            <h2>Shop</h2>
                        </a>
                    </li>*/}


                    {/*<li
                        className={
                            location.pathname === "/VirgilStanescu"
                                ? "active icon-box"
                                : "icon-box"
                        }
                    >
                        <i className="fas fa-basketball-ball"></i>
                        <Link to="/VirgilStanescu">
                            <h2>Virgil Stănescu</h2>
                        </Link>
                    </li>
                    <li
                        className={
                            location.pathname === "/CristinaNeagu"
                                ? "active icon-box"
                                : "icon-box"
                        }
                    >
                        <i className="fas fa-baseball-ball"></i>
                        <Link to="/CristinaNeagu">
                            <h2>Cristina Neagu</h2>
                        </Link>
                    </li>
                    <li
                        className={
                            location.pathname === "/AnaMariaBranza"
                                ? "active icon-box"
                                : "icon-box"
                        }
                    >
                        <i className="far fa-sword"></i>
                        <Link to="/AnaMariaBranza">
                            <h2>Ana-Maria Brânză</h2>
                        </Link>
                    </li>*/}

                    {/* <li
                    className={
                        location.pathname === "/CameliaPotec"
                            ? "active icon-box"
                            : "icon-box"
                    }
                >
                    <i className="fas fa-swimmer"></i>
                    <Link to="CameliaPotec">
                        <h2>Camelia Potec</h2>
                    </Link>
                </li> */}
                    {/*<li
                        className={
                            location.pathname === "/MihaiLeu"
                                ? "active icon-box"
                                : "icon-box"
                        }
                    >
                        <i className="fas fa-boxing-glove"></i>
                        <Link to="MihaiLeu">
                            <h2>Mihai Leu</h2>
                        </Link>
                    </li>*/}
                    {/* <li
                    className={
                        location.pathname === "/GianinaBeleaga"
                            ? "active icon-box"
                            : "icon-box"
                    }
                >
                    <i className="fas fa-water"></i>
                    <Link to="GianinaBeleaga">
                        <h2>Gianina Beleagă</h2>
                    </Link>
                </li>
                <li
                    className={
                        location.pathname === "/IonelaLiviaCozmiuc"
                            ? "active icon-box"
                            : "icon-box"
                    }
                >
                    <i className="fas fa-water"></i>
                    <Link to="IonelaLiviaCozmiuc">
                        <h2>Ionela-Livia Cozmiuc</h2>
                    </Link>
                </li> */}
                </ul>
                <nav role="navigation" className="d-block d-lg-none">
                    <div id="menuToggle">
                        <input type="checkbox"/>
                        <span></span>
                        <span></span>
                        <span></span>
                        <ul className="list-unstyled" id="menu">
                            <li className={location.pathname === "/" ? "active" : ""}>
                                <Link to="/">
                                    <img src={sportsHubLogo} alt={"SportsHubIcon"}
                                         style={{
                                             width: "7%",
                                         }}/>
                                    <span>Acasă</span>
                                </Link>
                            </li>

                            <li className={location.pathname === "/servicii" ? "active" : ""}>
                                <Link to="/">
                                    <i className="fas fa-briefcase"></i>
                                    <span>Servicii</span>
                                </Link>
                            </li>

                            <li className="icon-box">
                                <Link to="/">
                                    <i className="fas fa-users"></i>
                                    <span>Colorship</span>
                                </Link>
                            </li>

                            {/* <li
                                className={location.pathname === "/VirgilStanescu" ? "active" : ""}
                            >
                                <Link to="/VirgilStanescu">
                                    <i className="fas fa-basketball-ball"></i>
                                    <span>Virgil Stănescu</span>
                                </Link>
                            </li>
                            <li
                                className={location.pathname === "/CristinaNeagu" ? "active" : ""}
                            >
                                <Link to="/CristinaNeagu">
                                    <i className="fas fa-baseball-ball"></i>
                                    <span>Cristina Neagu</span>
                                </Link>
                            </li>
                            <li
                                className={location.pathname === "/AnaMariaBranza" ? "active" : ""}
                            >
                                <Link to="/AnaMariaBranza">
                                    <i className="far fa-sword"></i>
                                    <span>Ana-Maria Brânză</span>
                                </Link>
                            </li>*/}
                            {/* <li
                            className={
                                location.pathname === "/CameliaPotec"
                                    ? "active"
                                    : ""
                            }
                        >
                            <Link to="/CameliaPotec">
                                <i className="fas fa-swimmer"></i>
                                <span>Camelia Potec</span>
                            </Link>
                        </li> */}
                            {/*<li
                                className={location.pathname === "/MihaiLeu" ? "active" : ""}
                            >
                                <Link to="/MihaiLeu">
                                    <i className="fas fa-boxing-glove"></i>
                                    <span>Mihai Leu</span>
                                </Link>
                            </li>*/}

                            {/* <li
                            className={
                                location.pathname === "/GianinaBeleaga"
                                    ? "active"
                                    : ""
                            }
                        >
                            <Link to="/GianinaBeleaga">
                                <i className="fas fa-water"></i>

                                <span>Gianina Beleagă</span>
                            </Link>
                        </li>

                        <li
                            className={
                                location.pathname === "/IonelaLiviaCozmiuc"
                                    ? "active"
                                    : ""
                            }
                        >
                            <Link to="/IonelaLiviaCozmiuc">
                                <i className="fas fa-water"></i>

                                <span>Ionela Livia Cozmiuc</span>
                            </Link>
                        </li> */}
                        </ul>
                    </div>
                </nav>
            </header>
        </div>
    );
}

export default Header;
